import { cloneDeep, isNil, get } from 'lodash'
import axios from 'axios'
import config from '@/config'
import { debug } from 'Shared/utils/log'
import Database from '../../users-db'
import Storage from '../../users-storage'

export default {
  /**
   * Request all from database and listen for changes
   * @param context
   * @returns {Promise<void>}
   */
  getAll: async ({ state, commit, rootGetters }) => {
    // already listening to all documents?
    if (state.listenerAll) return Promise.resolve()
    const constraints = []
    if (!rootGetters['authentication/isAdmin']) {
      const orgId = rootGetters['authentication/organizationId']
      if (rootGetters['authentication/isSupplier']) {
        if (orgId !== 'mcs') {
          constraints.push(['supplier', '==', orgId])
        }
      } else {
        constraints.push(['organization', '==', orgId])
      }
    }
    if (!constraints.length) {
      debug('get ALL users')
    }
    const db = new Database(commit)
    await db
      .queryAndListen(constraints)
      .then((unsubscribe) => commit('listenerAll', unsubscribe))
    return Promise.resolve()
  },

  stopListeningAll: ({ state, commit }) => {
    if (!state.listenerAll) return
    state.listenerAll()
    commit('listenerAll', null)
  },

  /**
   * Request one from database and listen for changes
   * @param context
   * @param id
   * @returns {Promise<*>}
   */
  getOne: async ({ state, getters, commit }, id) => {
    if (Object.prototype.hasOwnProperty.call(state.listenerSingle, id))
      return getters.get(id)
    const db = new Database(commit)
    return db.readAndListen(id)
  },

  /**
   * Fetch one and set as editItem
   * @param commit
   * @param state
   * @param id
   */
  loadEditItem: async ({ commit, state }, id) => {
    commit('editItem', null)
    const exists = id in state.all
    const db = exists ? null : new Database(commit)
    const item = exists ? state.all[id] : await db.read(id)
    commit('editItem', cloneDeep(item))
    commit('editIsNew', false)
  },

  /**
   * Set editItem to empty item template
   * @param commit
   * @returns {Promise<void>}
   */
  resetEditItem: async ({ commit }) => {
    commit('editItem', {})
    commit('editIsNew', true)
  },

  /**
   * create or update item in database
   * @param context
   * @param item
   * @returns {Promise<void>}
   */
  set: async ({ commit }, item) => {
    const db = new Database(commit)
    const id = !isNil(item.id) && item.id ? item.id : null
    if ('id' in item) delete item.id
    return db.create(item, id)
  },

  /**
   * Update item in database
   * @param context
   * @param item
   * @returns {Promise<*>}
   */
  update: async ({ getters, dispatch, commit }, item) => {
    if (isNil(item.id)) return false
    const current = getters.get(item.id)
    const db = new Database(commit)
    return db.update(item).then((result) => {
      if (
        !current ||
        (Object.prototype.hasOwnProperty.call(item, 'displayName') &&
          current.displayName !== item.displayName)
      ) {
        // update firebase auth user
        dispatch('updateName', item.id)
      }
      return result
    })
  },

  /**
   * Create a new property and reset property name input
   */
  commitEditItem: ({ dispatch, state, commit }) => {
    if (state.editItem === null) return
    const item = state.editItem
    commit('editItem', null)
    dispatch('set', item)
  },

  /**
   * Delete item from database
   * @param commit
   * @param getters
   * @param id
   */
  delete: async ({ commit, getters }, id) => {
    if (getters.isDeletionPending(id)) return
    const db = new Database(commit)
    commit('addDeletionPending', id)
    db.delete(id).then(commit('removeDeletionPending', id))
  },

  fileUpload: async ({ rootGetters }, { upload, fileName }) => {
    const user = rootGetters['authentication/currentUser']
    const path = `${user.uid}/${fileName}`
    const storage = new Storage()
    return storage.uploadTask(path, upload)
  },

  fileRemove: async ({ rootGetters }, path) => {
    const user = rootGetters['authentication/currentUser']
    const storage = new Storage()
    const collection = storage.collectionName
    if (path.indexOf(`${collection}/${user.uid}/`) !== 0) return false
    const pathRel = path.substr(collection.length + 1)
    const fileRef = await storage.getFileRef(pathRel)
    return fileRef.delete()
  },

  /**
   * Change user's avatar image
   * @param rootState
   * @param dispatch
   * @param commit
   * @param upload
   * @param fileName
   * @returns {Promise<unknown>}
   */
  changePhoto: async (
    { rootState, dispatch, commit },
    { upload, fileName }
  ) => {
    const { user } = rootState.authentication
    const currentPhotoUrl = user.photoURL
    return new Promise((resolve, reject) => {
      dispatch('fileUpload', { upload, fileName })
        .then((fileRef) => {
          const updateDoc = {
            id: user.id,
            photoURL: fileRef.fullPath,
          }
          if (currentPhotoUrl && currentPhotoUrl !== fileRef.fullPath) {
            dispatch('fileRemove', currentPhotoUrl)
          }
          dispatch('update', updateDoc)
            .then(() => {
              commit('storageUrls/remove', currentPhotoUrl, { root: true })
              dispatch('storageUrls/get', updateDoc.photoURL, { root: true })
              resolve(true)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  },

  changeName: async ({ dispatch }, { id, name }) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'UserSetName',
        variables: { id, name },
        query: `
mutation UserSetName($id: ID!, $name: String) {
  userSetName(id: $id, name: $name)
}
`,
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.userSetName', null)
  },

  updateName: async ({ dispatch }, id) => {
    const token = await dispatch('authentication/getToken', false, {
      root: true,
    })
    const result = await axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'UserUpdateName',
        variables: { id },
        query: 'mutation UserUpdateName($id: ID!) { userUpdateName(id: $id) }',
      },
    })
    if (result.data.errors && result.data.errors[0]) {
      throw Error(result.data.errors[0].message)
    }
    return get(result, 'data.data.userUpdateName', null)
  },
}
