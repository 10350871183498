import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
} from 'firebase/auth'
import { get } from 'lodash'
import axios from 'axios'
import router from 'App/routes'
import UsersDB from 'Modules/users/users-db'
import config from '@/config'
import { DEFAULT_LANGUAGE } from '@/app/shared/config/language'

export default {
  /**
   * Callback fired when user login
   */
  stateChanged: async ({ dispatch, state, commit }, firebaseAuthUser) => {
    if (!firebaseAuthUser) {
      // action called without user => logout
      if (state.user) {
        dispatch('logout')
      }
      return
    }

    commit('app/loading', true, { root: true })

    const token = await firebaseAuthUser.getIdTokenResult()
    const claims = {
      role: get(token, 'claims.role'),
      org: get(token, 'claims.org'),
      sup: get(token, 'claims.sup'),
      lic: get(token, 'claims.lic'),
    }
    commit('claims', claims)

    const UserDb = new UsersDB(commit)

    await UserDb.readAndListen(firebaseAuthUser.uid, 'authentication/user')
      .then((user) => {
        // firestore user record exists
        return user
      })
      .catch(async () => {
        // create firestore user record
        const providerData = firebaseAuthUser.providerData[0]
        const { displayName, photoURL, email } = providerData

        const organization = get(token, 'claims.org', null)
        const supplier = get(token, 'claims.sup', null)

        return dispatch('users/set', {
          id: firebaseAuthUser.uid,
          displayName,
          photoURL,
          email,
          organization,
          supplier: supplier === true ? organization : supplier,
          role: get(token, 'claims.role', 'member'),
          language: DEFAULT_LANGUAGE,
        })
      })
      .then((user) => {
        commit('loginPending', false)
        // get user's language
        if ('language' in user) {
          commit('app/language', user.language, { root: true })
        }
        // load organization(s)
        const organizationLoaded = dispatch('organizations/getDefaults', null, {
          root: true,
        })
        // load users organization logo
        dispatch('organizations/getLogo', claims.org, { root: true })
        // load default items
        const defaultsLoading = [
          organizationLoaded.then(() =>
            dispatch('properties/getAll', null, { root: true })
          ),
          dispatch('contentTypes/getAll', null, {
            root: true,
          }),
          dispatch('collections/getAll', null, { root: true }),
          dispatch('templates/getAll', null, { root: true }),
          dispatch('basket/getAll', null, { root: true }),
          dispatch('notifications/getAll', null, { root: true }),
          dispatch('deployments/getAll', null, { root: true }),
        ]
        // load role
        if (claims.role) {
          // generate accessible routes map based on roles
          defaultsLoading.push(
            dispatch('permission/generateRoutes', claims, {
              root: true,
            }).then((accessRoutes) => {
              accessRoutes.forEach((route) => router.addRoute(route))
            })
          )
        }

        Promise.all(defaultsLoading).then(() => {
          commit('app/loading', false, { root: true })
          if (['login', 'email-action'].includes(router.currentRoute.name)) {
            router
              .replace(state.loginRedirect || { name: 'home' })
              .then(() => {
                const loginRoute = router.match({ name: 'login' })
                commit('tagsView/DEL_VISITED_VIEW', loginRoute, {
                  root: true,
                })
              })
              .catch(console.error)
          }
        })
      })
  },

  signIn: async ({ commit }, { email, password }) => {
    commit('loginPending', true)
    commit('loginError', null)
    commit('user', undefined)
    commit('claims', {})

    const auth = getAuth()
    return signInWithEmailAndPassword(auth, email, password)
      .then(() => true)
      .catch((error) => {
        commit('loginPending', false)
        commit('loginError', error)
        commit('user', null)
        throw error
      })
  },

  /**
   * Callback fired when user logout
   */
  logout: async ({ commit }) => {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        commit('user', null)
        commit('claims', {})
        commit('users/flush', null, { root: true })
        commit('organizations/flush', null, { root: true })
        window.location.href = '/'
        return true
      })
      .finally(() => {
        commit('app/loading', false, { root: true })
      })
  },

  getToken: async (_, forceRefresh = false) => {
    const auth = getAuth()
    if (!auth || !auth.currentUser) return null
    return auth.currentUser.getIdToken(!!forceRefresh)
  },

  passwordReset: async ({ commit, rootState }, { email }) => {
    commit('resetPending', true)
    commit('resetError', null)
    commit('resetDone', false)
    const auth = getAuth()
    if (rootState.app.language) {
      auth.languageCode = rootState.app.language
    } else {
      auth.useDeviceLanguage()
    }

    return sendPasswordResetEmail(auth, email)
      .then(() => {
        commit('resetDone', true)
        return true
      })
      .catch((error) => {
        commit('resetError', error)
        throw error
      })
      .finally(() => commit('resetPending', false))
  },

  register: async ({ dispatch }, { email, name }) => {
    const token = await dispatch('getToken')
    return axios({
      url: config.api.graphQl,
      method: 'post',
      headers: { authorization: token ? `Bearer ${token}` : '' },
      data: {
        operationName: 'Register',
        variables: { e: email, n: name },
        query:
          'mutation Register($e: String!, $n: String) { userRegisterDomain(email: $e, name: $n) }',
      },
    }).then((result) => {
      if (result.data.errors && result.data.errors[0]) {
        throw Error(result.data.errors[0].message)
      }
      return get(result, 'data.data.userRegisterDomain', null)
    })
  },

  /**
   * Email Link Actions
   */
  verifyPasswordResetCode: async (context, actionCode) => {
    const auth = getAuth()
    return verifyPasswordResetCode(auth, actionCode)
  },
  confirmPasswordReset: async (
    { dispatch, commit },
    { actionCode, newPassword, accountEmail, continueUrl = null }
  ) => {
    const auth = getAuth()
    return confirmPasswordReset(auth, actionCode, newPassword).then(() => {
      commit('loginRedirect', continueUrl)
      return dispatch('signIn', {
        email: accountEmail,
        password: newPassword,
      })
    })
  },
  restoreEmail: async (context, actionCode) => {
    const auth = getAuth()
    return checkActionCode(auth, actionCode).then((info) => {
      const restoredEmail = info.data.email
      return applyActionCode(auth, actionCode).then(() => {
        return restoredEmail
      })
    })
  },
  verifyEmail: async (context, actionCode) => {
    const auth = getAuth()
    return applyActionCode(auth, actionCode)
  },
}
